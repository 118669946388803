import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './GreyHatSupportBot.css';
import './Global.css';

const GreyHatSupportBot = () => {
    return (
        <>
            <Header />
            <div className="container">
                <h1>GreyHat Support Bot</h1>
                <p>Our AI-based support bot is under development and will be available soon. Stay tuned for updates!</p>
            </div>
            <Footer />
        </>
    );
};


export default GreyHatSupportBot;
