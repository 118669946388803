import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Contact.css";
import "./Global.css";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();

  const handleNavigate = (page) => {
    navigate(page);
  };

  return (
    <>
      <Header />
      <div className="contact-container">
        <div
          className="contact-card"
          onClick={() => handleNavigate("/greyhat-support-bot")}
          onMouseEnter={(e) => e.currentTarget.classList.add("hover")}
          onMouseLeave={(e) => e.currentTarget.classList.remove("hover")}
        >
          <h2>GreyHat Support Bot</h2>
          <p>Get support from our upcoming AI-based assistant.</p>
        </div>
        <div
          className="contact-card"
          onClick={() => handleNavigate("/email-support")}
          onMouseEnter={(e) => e.currentTarget.classList.add("hover")}
          onMouseLeave={(e) => e.currentTarget.classList.remove("hover")}
        >
          <h2>Email Support</h2>
          <p>Contact us directly through email.</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
