import React from "react";
import "./Settings.css";
import "../../Global.css";

const Settings = () => {
  return (
    <div className="settings-container">
      <h1>Settings</h1>
      <p>
        Customize your experience by adjusting your preferences, account
        settings, and privacy controls.
      </p>
      {/* Additional content can go here */}
      <p className="coming-soon">
        More settings options are on the way! As a solo developer, I'm working
        hard to provide the best experience for you. Thank you for your patience
        and understanding.
      </p>
      <a href="/dashboard" className="back-to-dashboard">
        Back to Dashboard
      </a>
    </div>
  );
};

export default Settings;
