import React from "react";
import "./Notifications.css";
import "../../Global.css";

const Notifications = () => {
  return (
    <div className="notifications-container">
      <h1>Notifications</h1>
      <p>
        Stay up-to-date with the latest alerts and messages. All your
        notifications will appear here.
      </p>
      {/* Additional content can go here */}
      <p className="coming-soon">
        More updates are on the way! I'm working tirelessly to enhance your
        experience. As a solo developer, I truly appreciate your patience and
        support during this process.
      </p>
      <a href="/dashboard" className="back-to-dashboard">
        Back to Dashboard
      </a>
    </div>
  );
};

export default Notifications;
