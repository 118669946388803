import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./JobApply.css";
import "./Global.css";

const JobApply = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Handle form submission logic here

    setTimeout(() => {
      alert("Application submitted successfully!");
      setIsSubmitting(false);
    }, 2000); // Simulate a delay for form submission
  };

  return (
    <>
      <Header />
      <div className="job-apply-container">
        <h1 className="text-center">Join Our Team</h1>
        <p>
          At GreyHat Solutions, we are always looking for passionate and
          dedicated individuals to join our team. If you are interested in
          working with us, please fill out the application form below.
        </p>

        <form className="job-apply-form" onSubmit={handleSubmit}>
          <label htmlFor="fullName">
            Full Name:
            <input type="text" id="fullName" name="fullName" required />
          </label>
          <label htmlFor="email">
            Email Address:
            <input type="email" id="email" name="email" required />
          </label>
          <label htmlFor="phoneNumber">
            Phone Number:
            <input type="tel" id="phoneNumber" name="phoneNumber" required />
          </label>
          <label htmlFor="position">
            Position Applying For:
            <select id="position" name="position" required>
              <option value="">Select a position</option>
              <option value="network-security">
                Network Security Specialist
              </option>
              <option value="cloud-engineer">Cloud Engineer</option>
              <option value="web-developer">Web Developer</option>
              <option value="software-engineer">Software Engineer</option>
              <option value="it-consultant">IT Consultant</option>
              <option value="data-analyst">Data Analyst</option>
              <option value="ai-specialist">AI Specialist</option>
              <option value="other">Other</option>
            </select>
          </label>
          <label htmlFor="coverLetter">
            Cover Letter:
            <textarea
              id="coverLetter"
              name="coverLetter"
              rows="5"
              required
            ></textarea>
          </label>
          <label htmlFor="resume">
            Attach Resume (PDF or DOC):
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".pdf,.doc,.docx"
              required
            />
          </label>
          <button type="submit" className="cta-button" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit Application"}
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default JobApply;
