// src/pages/FAQs.js
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./FAQs.css";
import "./Global.css";

const FAQs = () => {
  return (
    <>
      <Header />
      <div className="faqs-container">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>What services do you offer?</h3>
          <p>
            We offer a wide range of IT services including network security,
            cloud solutions, software development, and more.
          </p>
        </div>
        <div className="faq-item">
          <h3>How can I get support?</h3>
          <p>
            You can contact our support team through the dashboard once you’re
            logged in. For general inquiries, please use our contact page.
          </p>
        </div>
        <div className="faq-item">
          <h3>What are your business hours?</h3>
          <p>
            We are available Monday to Friday, from 9 AM to 6 PM. Emergency
            support is available 24/7.
          </p>
        </div>
        <div className="faq-item">
          <h3>How do I reset my password?</h3>
          <p>
            You can reset your password by clicking the "Forgot Password" link
            on the login page.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQs;
