import React from "react";
import "./KnowledgeBase.css";
import "../../Global.css";

const KnowledgeBase = () => {
  return (
    <div className="knowledgebase-container">
      <h1>Knowledge Base</h1>
      <p>
        Find detailed articles, guides, and documentation to help you use our
        services.
      </p>
      {/* Additional content can go here */}
      <p className="coming-soon">
        More resources are being added! I'm a one-person team working diligently
        to make this knowledge base comprehensive and helpful. Your patience and
        support mean the world to me.
      </p>
      <a href="/dashboard" className="back-to-dashboard">
        Back to Dashboard
      </a>
    </div>
  );
};

export default KnowledgeBase;
