import React from "react";
import "./Subscriptions.css";
import "../../Global.css";

const Subscriptions = () => {
  return (
    <div className="subscriptions-container">
      <h1>Subscriptions</h1>
      <p>
        Manage your current subscriptions, renew plans, and explore new services
        available to you.
      </p>
      {/* Additional content can go here */}
      <p className="coming-soon">
        Exciting features are coming soon! I'm working around the clock to bring
        you the best possible experience. Thank you for your patience and
        continued support.
      </p>
      <a href="/dashboard" className="back-to-dashboard">
        Back to Dashboard
      </a>
    </div>
  );
};

export default Subscriptions;
