import React from "react";
import "./Profile.css";
import "../../Global.css";

const Profile = () => {
  return (
    <div className="profile-container">
      <h1>Profile Overview</h1>
      <p>
        Welcome to your profile page! Here you can update your personal
        information, change your password, and view your account details.
      </p>
      {/* Additional content can go here */}
      <p className="coming-soon">
        More features are coming soon! Thank you for your patience as I work
        hard to bring you the best experience. I'm a one-man army building this,
        and I appreciate your understanding and support!
      </p>
      <a href="/dashboard" className="back-to-dashboard">
        Back to Dashboard
      </a>
    </div>
  );
};

export default Profile;
