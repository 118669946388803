// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import Team from './pages/Team';
import StaffLogin from './pages/auth/StaffLogin';
import ClientLogin from './pages/auth/ClientLogin';
import Register from './pages/auth/Register';
import KnowledgeBase from './pages/dashboard/content/KnowledgeBase';
import Dashboard from './pages/dashboard/Dashboard';
import FAQs from './pages/FAQs';
import TermsOfService from './pages/ToS';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Contact from './pages/Contact';
import GreyHatSupportBot from './pages/GreyHatSupportBot';
import EmailSupport from './pages/EmailSupport';
import JobApply from './pages/JobApply';
import Profile from './pages/dashboard/content/Profile';
import Notifications from './pages/dashboard/content/Notifications';
import Subscriptions from './pages/dashboard/content/Subscriptions';
import Settings from './pages/dashboard/content/Settings';

const App = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/team" element={<Team />} />
      <Route path="/staff-login" element={<StaffLogin />} />
      <Route path="/login" element={<ClientLogin />} />
      <Route path="/register" element={<Register />} />
      <Route path="/faqs" element={<FAQs />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/greyhat-support-bot" element={<GreyHatSupportBot />} />
      <Route path="/email-support" element={<EmailSupport />} />
      <Route path="/JobApply" element={<JobApply />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/profile" element={<Profile />} />
      <Route path="/dashboard/notifications" element={<Notifications />} />
      <Route path="/dashboard/subscriptions" element={<Subscriptions />} />
      <Route path="/dashboard/settings" element={<Settings />} />
      <Route path="/dashboard/knowledge-base" element={<KnowledgeBase />} />
    </Routes>
  </Router>
);

export default App;
