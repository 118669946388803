import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './Services.css';
import './Global.css';

const services = [
  {
    category: "Network and Security Services",
    services: [
      { title: "Virtual Private Network (VPN) Setup", description: "Setup for secure remote access.", tags: ["VPN", "Network Security", "Remote Access", "Easy"] },
      { title: "Firewall Management", description: "Manage and configure firewalls.", tags: ["Firewall", "Network Security", "Cybersecurity", "Intermediate"] },
      { title: "Intrusion Detection and Prevention Systems (IDPS)", description: "Implement and monitor IDPS.", tags: ["IDPS", "Network Security", "Monitoring", "Challenging"] },
    ],
  },
  {
    category: "Cloud and Data Services",
    services: [
      { title: "Data Backup and Recovery", description: "Ensure data continuity and recovery.", tags: ["Backup", "Cloud Storage", "Continuity", "Easy"] },
      { title: "Database Management", description: "Optimize and manage databases.", tags: ["Database", "Cloud Services", "Optimization", "Intermediate"] },
      { title: "Big Data Services", description: "Scalable data analytics solutions.", tags: ["Big Data", "Data Analytics", "Scalability", "Challenging"] },
    ],
  },
  {
    category: "Web and Digital Services",
    services: [
      { title: "Email Marketing Services", description: "Automated email marketing solutions.", tags: ["Email Marketing", "Digital Marketing", "Automation", "Easy"] },
      { title: "Content Management Systems (CMS)", description: "Develop and manage CMS solutions.", tags: ["CMS", "Web Development", "SEO", "Intermediate"] },
      { title: "E-commerce Solutions", description: "Build and integrate online stores.", tags: ["E-commerce", "Online Store", "Payment Integration", "Challenging"] },
    ],
  },
  {
    category: "Software and Application Services",
    services: [
      { title: "Software Support and Maintenance", description: "Support and maintenance for software.", tags: ["Software Support", "Technical Support", "Updates", "Easy"] },
      { title: "Mobile App Development", description: "Development of mobile applications.", tags: ["App Development", "Mobile Software", "User Experience", "Intermediate"] },
      { title: "Custom Software Development", description: "Development of custom software solutions.", tags: ["Custom Software", "Software Solutions", "Tailored Solutions", "Challenging"] },
    ],
  },
  {
    category: "AI and Automation Services",
    services: [
      { title: "Smart Home Automation", description: "Automation solutions for smart homes.", tags: ["Home Automation", "Smart Devices", "Convenience", "Easy"] },
      { title: "Machine Learning Services", description: "Services related to machine learning.", tags: ["Machine Learning", "AI Services", "Algorithms", "Intermediate"] },
      { title: "Artificial Intelligence (AI) Solutions", description: "Solutions utilizing artificial intelligence.", tags: ["AI Solutions", "Advanced Automation", "Neural Networks", "Challenging"] },
    ],
  },
  {
    category: "IT Support and Consulting",
    services: [
      { title: "IT Repair Services", description: "Repair services for IT equipment.", tags: ["IT Repair", "Tech Support", "Troubleshooting", "Easy"] },
      { title: "Help Desk and Technical Support", description: "Help desk and technical support services.", tags: ["Help Desk", "Customer Support", "Issue Resolution", "Intermediate"] },
      { title: "Virtual CIO Services", description: "Virtual Chief Information Officer (CIO) services.", tags: ["Virtual CIO", "IT Strategy", "Consulting", "Challenging"] },
    ],
  },
  {
    category: "Analytics and Business Intelligence",
    services: [
      { title: "Data Visualization", description: "Visualization of data using BI tools.", tags: ["Data Visualization", "BI Tools", "Reporting", "Easy"] },
      { title: "Business Intelligence (BI) Solutions", description: "Solutions for business intelligence.", tags: ["BI Solutions", "Data Analysis", "Insights", "Intermediate"] },
      { title: "Predictive Analytics", description: "Analytics for predicting trends and patterns.", tags: ["Predictive Analytics", "Forecasting", "Trends", "Challenging"] },
    ],
  },
  {
    category: "Communication and Collaboration",
    services: [
      { title: "Collaboration Tools (e.g., Microsoft Teams, Slack)", description: "Tools for team collaboration and communication.", tags: ["Collaboration Tools", "Team Communication", "Productivity", "Easy"] },
      { title: "Video Conferencing Solutions", description: "Solutions for online video conferencing.", tags: ["Video Conferencing", "Online Meetings", "Connectivity", "Intermediate"] },
      { title: "Unified Communications", description: "Integrated messaging and communication solutions.", tags: ["Unified Communications", "Integrated Messaging", "Efficiency", "Challenging"] },
    ],
  },
  {
    category: "Hardware and Custom Builds",
    services: [
      { title: "Peripheral Setup and Configuration", description: "Setup and configuration of peripherals.", tags: ["Peripheral Setup", "Device Configuration", "Optimization", "Easy"] },
      { title: "Custom PC Building", description: "Building custom PCs.", tags: ["Custom PC", "Hardware Customization", "Performance", "Intermediate"] },
      { title: "Hardware Procurement and Installation", description: "Procurement and installation of tech hardware.", tags: ["Hardware Procurement", "Installation", "Tech Equipment", "Challenging"] },
    ],
  },
  {
    category: "Other Specialized Services",
    services: [
      { title: "Data Entry and Management Services", description: "Data entry and management services.", tags: ["Data Entry", "Data Management", "Accuracy", "Easy"] },
      { title: "Disaster Recovery Planning", description: "Planning for disaster recovery and business continuity.", tags: ["Disaster Recovery", "Business Continuity", "Resilience", "Intermediate"] },
      { title: "Virtualization Solutions", description: "Solutions for virtualization and virtual machines.", tags: ["Virtualization", "Virtual Machines", "Resource Management", "Challenging"] },
    ],
  },
];

const Services = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredServices = services
    .map((category) => ({
      ...category,
      services: category.services.filter((service) =>
        selectedTags.length > 0
          ? selectedTags.every((tag) => service.tags.includes(tag))
          : service.title.toLowerCase().includes(searchQuery) || service.description.toLowerCase().includes(searchQuery)
      ),
    }))
    .filter((category) => category.services.length > 0);

  return (
    <div className="page-container">
      <Header />
      <section className="services-section">
        <div className="service-search">
          <input
            type="text"
            placeholder="Search for a service"
            value={searchQuery}
            onChange={handleSearch}
            className="service-search-box"
          />
          <button className="filter-by-tag-btn" onClick={() => alert('Filter by Tag functionality to be implemented')}>
            Filter by tag
          </button>
          {selectedTags.length > 0 && (
            <button className="clear-filters-btn" onClick={() => setSelectedTags([])}>
              Clear filters
            </button>
          )}
        </div>
        {filteredServices.map((category, index) => (
          <div key={index} className="service-category">
            <h3 onClick={() => toggleCategory(category.category)}>{category.category}</h3>
            {expandedCategory === category.category && (
              <div className="service-cards">
                {category.services.map((service, idx) => (
                  <Link key={idx} to={`/${encodeURIComponent(category.category.replace(/\s+/g, ''))}`} className="service-link">
                    <div className="service-card">
                      <h4>{service.title}</h4>
                      <p>{service.description}</p>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </section>
      <Footer />
    </div>
  );
};

export default Services;
