import React from 'react';
import './Global.css'; // or a separate CSS file for global styles
import Header from '../components/Header'; // Adjust path according to your project structure
import Footer from '../components/Footer'; // Adjust path according to your project structure
import './Team.css'; // or a separate CSS file for Team if needed

const teamMembers = {
  Administration: [
    {
      name: "John Doe",
      role: "CEO",
      bio: "John has over 20 years of experience in IT and cybersecurity.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/johndoe",
        twitter: "https://twitter.com/johndoe",
      },
    },
    {
      name: "Jane Smith",
      role: "CFO",
      bio: "Jane manages the company's finances with precision and care.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/janesmith",
        twitter: "https://twitter.com/janesmith",
      },
    },
  ],
  WebDevelopers: [
    {
      name: "Programmer1",
      role: "Frontend Developer",
      bio: "Expert in creating stunning and responsive interfaces.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/programmer1",
        twitter: "https://twitter.com/programmer1",
      },
    },
    {
      name: "Programmer2",
      role: "Backend Developer",
      bio: "Specializes in robust and scalable server-side applications.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/programmer2",
        twitter: "https://twitter.com/programmer2",
      },
    },
  ],
  NetworkTechnicians: [
    {
      name: "Network1",
      role: "Network Engineer",
      bio: "Ensures seamless connectivity across all devices.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/network1",
        twitter: "https://twitter.com/network1",
      },
    },
    {
      name: "Network2",
      role: "System Administrator",
      bio: "Manages and secures all network operations.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/network2",
        twitter: "https://twitter.com/network2",
      },
    },
  ],
  CloudDatabaseSpecialists: [
    {
      name: "Cloud1",
      role: "Cloud Architect",
      bio: "Designs and implements scalable cloud infrastructure.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/cloud1",
        twitter: "https://twitter.com/cloud1",
      },
    },
    {
      name: "Database1",
      role: "Database Administrator",
      bio: "Maintains and optimizes all database systems.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/database1",
        twitter: "https://twitter.com/database1",
      },
    },
  ],
  PythonExperts: [
    {
      name: "Python1",
      role: "Python Developer",
      bio: "Develops efficient Python scripts for automation.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/python1",
        twitter: "https://twitter.com/python1",
      },
    },
    {
      name: "Python2",
      role: "Data Scientist",
      bio: "Transforms data into actionable insights using Python.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/python2",
        twitter: "https://twitter.com/python2",
      },
    },
  ],
  AppleGurus: [
    {
      name: "Apple1",
      role: "MacOS Specialist",
      bio: "Expert in all things MacOS and iOS.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/apple1",
        twitter: "https://twitter.com/apple1",
      },
    },
    {
      name: "Apple2",
      role: "iOS Developer",
      bio: "Creates intuitive and user-friendly iOS applications.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/apple2",
        twitter: "https://twitter.com/apple2",
      },
    },
  ],
  AIWizards: [
    {
      name: "AI1",
      role: "AI Researcher",
      bio: "Explores new frontiers in artificial intelligence.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/ai1",
        twitter: "https://twitter.com/ai1",
      },
    },
    {
      name: "AI2",
      role: "Machine Learning Engineer",
      bio: "Builds machine learning models to solve complex problems.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/ai2",
        twitter: "https://twitter.com/ai2",
      },
    },
  ],
  WindowsMasters: [
    {
      name: "Windows1",
      role: "Windows Server Specialist",
      bio: "Manages and optimizes Windows Server environments.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/windows1",
        twitter: "https://twitter.com/windows1",
      },
    },
    {
      name: "Windows2",
      role: "Windows Desktop Specialist",
      bio: "Expert in all aspects of Windows desktop systems.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/windows2",
        twitter: "https://twitter.com/windows2",
      },
    },
  ],
  CybersecurityElites: [
    {
      name: "Security1",
      role: "Penetration Tester",
      bio: "Identifies vulnerabilities and secures systems.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/security1",
        twitter: "https://twitter.com/security1",
      },
    },
    {
      name: "Security2",
      role: "Cybersecurity Analyst",
      bio: "Monitors and responds to security threats.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/security2",
        twitter: "https://twitter.com/security2",
      },
    },
  ],
  TBD: [
    {
      name: "FutureMember1",
      role: "TBD Role",
      bio: "Future team member description here.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/futuremember1",
        twitter: "https://twitter.com/futuremember1",
      },
    },
    {
      name: "FutureMember2",
      role: "TBD Role",
      bio: "Future team member description here.",
      image: "path-to-image.jpg",
      social: {
        linkedin: "https://www.linkedin.com/in/futuremember2",
        twitter: "https://twitter.com/futuremember2",
      },
    },
  ],
};

const Team = () => {
  return (
    <div className="page-container">
      <Header />
      <div className="team-page">
        <h2>Meet the Team</h2>
        {Object.keys(teamMembers).map((category, index) => (
          <div key={index} className="team-category">
            <h3>{category.replace(/([A-Z])/g, ' $1')}</h3> {/* Formats camelCase to normal text */}
            <div className="team-members">
              {teamMembers[category].map((member, idx) => (
                <div key={idx} className="team-member card">
                  <img src={member.image} alt={member.name} className="member-photo" />
                  <h4>{member.name}</h4>
                  <p className="member-role">{member.role}</p>
                  <p className="member-bio">{member.bio}</p>
                  <div className="social-links">
                    <a href={member.social.linkedin} target="_blank" rel="noopener noreferrer">
                      LinkedIn
                    </a>
                    <a href={member.social.twitter} target="_blank" rel="noopener noreferrer">
                      Twitter
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Team;
