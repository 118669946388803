import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./EmailSupport.css";
import "./Global.css";
import { useNavigate } from "react-router-dom";

const EmailSupport = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
    service: "",
    agreeToTerms: false,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValues.agreeToTerms) {
      // Handle email sending logic here
      const formData = new FormData();
      formData.append("name", formValues.name);
      formData.append("email", formValues.email);
      formData.append("message", formValues.message);
      formData.append("service", formValues.service);
      formData.append("attachment", e.target.attachment.files[0]);

      // Send formData to server or perform further processing
      alert("Email sent successfully!");
      navigate(-1);
    } else {
      alert("You must agree to the terms of service before sending the email.");
    }
  };

  return (
    <>
      <Header />
      <div
        className="container email-support-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "40px",
        }}
      >
        <div
          className="info-panel"
          style={{
            flex: "1",
            marginRight: "40px",
            padding: "20px",
            backgroundColor: "#333",
            borderRadius: "10px",
            color: "#ccc",
          }}
        >
          <h2 style={{ color: "#FF9800" }}>Interested in supporting others?</h2>
          <p>
            Join our team and help others by providing top-notch support! We’re
            always looking for passionate individuals to work with us.
          </p>
          <button
            className="cta-button"
            onClick={() => navigate("/JobApply")}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              backgroundColor: "#00d1b2",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              fontSize: "1rem",
              cursor: "pointer",
            }}
          >
            Apply Here
          </button>
        </div>

        <div
          className="form-panel"
          style={{
            flex: "2",
            padding: "20px",
            backgroundColor: "#282c34",
            borderRadius: "10px",
            color: "#ccc",
          }}
        >
          <h1
            style={{
              marginBottom: "40px",
              color: "#FF9800",
              textAlign: "center",
            }}
          >
            Contact Us
          </h1>
          <form onSubmit={handleSubmit} className="email-form">
            <label>
              Select a service:
              <select
                name="service"
                value={formValues.service}
                onChange={handleChange}
                required
              >
                <option value="">Select an option</option>
                <option value="network-security">
                  Network and Security Services
                </option>
                <option value="cloud-data">Cloud and Data Services</option>
                <option value="web-digital">Web and Digital Services</option>
                <option value="software-application">
                  Software and Application Services
                </option>
                <option value="ai-automation">
                  AI and Automation Services
                </option>
                <option value="it-support-consulting">
                  IT Support and Consulting
                </option>
                <option value="analytics-bi">
                  Analytics and Business Intelligence
                </option>
                <option value="communication-collaboration">
                  Communication and Collaboration
                </option>
                <option value="hardware-custom-builds">
                  Hardware and Custom Builds
                </option>
                <option value="other-specialized">
                  Other Specialized Services
                </option>
              </select>
            </label>
            <label>
              Your Name:
              <input
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Your Email:
              <input
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Your Message:
              <textarea
                name="message"
                value={formValues.message}
                onChange={handleChange}
                required
              ></textarea>
            </label>
            <label>
              Attach a file (optional):
              <input type="file" name="attachment" />
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                name="agreeToTerms"
                checked={formValues.agreeToTerms}
                onChange={handleChange}
                required
              />
              I agree to the{" "}
              <a href="/terms-of-service" style={{ color: "#03A9F4" }}>
                Terms of Service
              </a>
            </label>
            <button
              type="submit"
              disabled={!formValues.agreeToTerms}
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                backgroundColor: "#00d1b2",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                fontSize: "1rem",
                cursor: "pointer",
              }}
            >
              Send
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmailSupport;
