import React from "react";
import { useNavigate } from "react-router-dom";
import "./Global.css";

const PrivacyPolicy = () => {
  const navigate = useNavigate(); // Corrected useNavigate

  const handleBack = () => {
    navigate(-1); // Corrected navigation
  };

  return (
    <div className="container" style={{ padding: "40px", lineHeight: "1.8" }}>
      <h1 className="text-center" style={{ marginBottom: "40px" }}>
        Privacy Policy
      </h1>
      <p>
        At GreyHat Solutions, we are committed to protecting your privacy. This
        Privacy Policy outlines the types of information we collect, how we use
        it, and the measures we take to ensure your information is kept secure.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> This includes your name, email
          address, phone number, and other details you provide when you contact
          us or register for our services.
        </li>
        <li>
          <strong>Usage Data:</strong> We collect information on how you
          interact with our website, including your IP address, browser type,
          pages visited, and time spent on each page.
        </li>
        <li>
          <strong>Cookies:</strong> We use cookies to enhance your browsing
          experience, track usage patterns, and improve our services.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>
          <strong>To Provide Services:</strong> We use your personal information
          to provide and manage the services you request from us.
        </li>
        <li>
          <strong>To Improve Our Website:</strong> Usage data helps us
          understand how our website is used and identify areas for improvement.
        </li>
        <li>
          <strong>To Communicate with You:</strong> We may use your contact
          information to respond to inquiries, send updates, and provide
          customer support.
        </li>
        <li>
          <strong>For Marketing:</strong> With your consent, we may use your
          information to send promotional materials and special offers.
        </li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not share your personal information with third parties except as
        required by law or to provide services on your behalf. This includes:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          trusted service providers who assist us in operating our website and
          delivering services.
        </li>
        <li>
          <strong>Legal Obligations:</strong> We may disclose your information
          to comply with legal obligations, such as responding to court orders
          or government requests.
        </li>
      </ul>

      <h2>4. Security of Your Information</h2>
      <p>
        We take the security of your personal information seriously. We
        implement industry-standard security measures to protect your data from
        unauthorized access, alteration, disclosure, or destruction.
      </p>

      <h2>5. Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal
        information. You may also object to the processing of your data or
        request data portability. To exercise these rights, please contact us at
        ncacord@protonmail.com.
      </p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal obligations. We will notify you of any
        significant changes by posting the updated policy on our website.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at ncacord@protonmail.com.
      </p>

      <div className="text-center">
        <button
          className="cta-button"
          onClick={handleBack}
          style={{
            marginTop: "40px",
            padding: "10px 20px",
            backgroundColor: "#00d1b2",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
