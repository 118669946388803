import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './StaffLogin.css';
import '../Global.css';

const StaffLogin = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [showSplash, setShowSplash] = useState(false);
  const [username, setUsername] = useState('');
  const [showClientRedirect, setShowClientRedirect] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/staff', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.is_staff) {
          setUsername(data.username);
          setShowSplash(true);
          setTimeout(() => {
            window.location.href = '/dashboard';
          }, 2000);
        } else {
          setShowClientRedirect(true);
        }
      } else {
        setShowClientRedirect(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setShowClientRedirect(true);
    }
  };

  return (
    <>
      <Header />
      <div className="staff-login-container">
        <div className="login-form-container">
          <h2>Staff Login</h2>
          <form onSubmit={handleSubmit} className="staff-login-form">
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <button type="submit">Login</button>
          </form>
          <button className="back-to-home" onClick={() => window.location.href = '/'}>
            Back to Home page
          </button>
        </div>

        {showSplash && (
          <div className="splash-screen">
            <h2>Welcome back, {username}!</h2>
          </div>
        )}

        {showClientRedirect && (
          <div className="client-redirect-card">
            <h3>You're probably looking for this</h3>
            <p>
              <a href="/ClientLogin">Client Login</a>
            </p>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default StaffLogin;
