import React, { useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./ClientLogin.css";
import "../Global.css";

const ClientLogin = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/auth", formData);
      localStorage.setItem("token", response.data.token);
      setSuccess(true);
      setTimeout(() => {
        window.location.href = "/dashboard"; // Redirect to dashboard after login
      }, 2000); // Optional delay to show success message
    } catch (err) {
      setError("Invalid credentials");
    }
  };

  return (
    <>
      <Header />
      <div className="client-login-container">
        <div className="login-form-container">
          <h2>Client Login</h2>
          <form onSubmit={handleSubmit} className="client-login-form">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <button type="submit">Login</button>
          </form>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">Login successful! Redirecting...</p>}
          <p className="register-link">
            Don't have an account? <a href="/Register">Register here</a>
          </p>
          <button className="back-to-home" onClick={() => window.location.href = '/'}>
            Back to Home page
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClientLogin;
