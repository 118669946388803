import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import "../Global.css";

const Dashboard = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [user, setUser] = useState({
    name: "John Doe",
    location: "New York, USA",
  });

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  // Function to update the time every minute
  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const timeString = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const dateString = now.toLocaleDateString();
      setCurrentTime(`${dateString} - ${timeString}`);
    };

    updateClock();
    const interval = setInterval(updateClock, 60000);

    return () => clearInterval(interval);
  }, []);

  // Mock function to simulate fetching user data
  useEffect(() => {
    const fetchUserData = () => {
      setUser({ name: "Jane Smith", location: "San Francisco, USA" });
    };
    fetchUserData();
  }, []);

  // Logic to grab relevant data for each section
  const ticketStatusData = [];
  const suggestedTasksData = [];
  const messagesData = []; // Replace with an empty array or fetch the messages data from an API

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <div className="header-content">
          <span className="dashboard-title">GreyHat Dashboard</span>{" "}
          {/* New title */}
          <span className="user-info">
            {currentTime} | {user.name} | {user.location}
          </span>
        </div>
      </header>

      <nav className={`sidebar-nav ${isNavExpanded ? "expanded" : ""}`}>
        <button className="hamburger-btn" onClick={toggleNav}>
          {isNavExpanded ? "🡰" : "🡲"}
        </button>
        <ul className="nav-list">
          <li className="nav-item">
            <a href="/dashboard/profile" className="icon-btn">
              <span className="icon">👤</span>
              <span className="nav-text">Profile</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/notifications" className="icon-btn">
              <span className="icon">🔔</span>
              <span className="nav-text">Notifications</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/subscriptions" className="icon-btn">
              <span className="icon">💳</span>
              <span className="nav-text">Subscriptions</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/knowledge-base" className="icon-btn">
              <span className="icon">📚</span>
              <span className="nav-text">Knowledge Base</span>
            </a>
          </li>
          <li className="nav-item settings">
            <a href="/dashboard/settings" className="icon-btn">
              <span className="icon">⚙️</span>
              <span className="nav-text">Settings</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/faqs" className="icon-btn">
              <span className="icon">🛠️</span>
              <span className="nav-text">Support</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/" className="icon-btn">
              <span className="icon">🏠</span>
              <span className="nav-text">Back to Home page</span>
            </a>
          </li>
        </ul>
      </nav>

      <div className="dashboard-content">
        <div className="grid-item">
          <div className="widget">
            <h3>Ticket Status</h3>
            <p>Status of currently active tickets.</p>
            {/* Display ticket status data */}
            {ticketStatusData && (
              <ul>
                {ticketStatusData.map((ticket) => (
                  <li key={ticket.id}>{ticket.title}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="grid-item">
          <div className="widget">
            <h3>Suggested Tasks</h3>
            <p>A list of suggested tasks to improve or complete profile.</p>
            {/* Display suggested tasks data */}
            {suggestedTasksData && (
              <ul>
                {suggestedTasksData.map((task) => (
                  <li key={task.id}>{task.title}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="grid-item">
          <div className="widget">
            <h3>Messages</h3>
            <p>Recent communications and messages.</p>
            {/* Display messages data */}
            {messagesData && (
              <ul>
                {messagesData.map((message) => (
                  <li key={message.id}>{message.content}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
