import React from "react";
import { useNavigate } from "react-router-dom";
import "./Global.css";

const TermsOfService = () => {
  const navigate = useNavigate(); // Corrected useNavigate

  const handleBack = () => {
    navigate(-1); // Corrected navigation
  };

  return (
    <div className="container" style={{ padding: "40px", lineHeight: "1.8" }}>
      <h1 className="text-center" style={{ marginBottom: "40px" }}>
        Terms of Service
      </h1>
      <p>
        Welcome to GreyHat Solutions. By accessing or using our website and
        services, you agree to comply with and be bound by the following terms
        and conditions. If you do not agree with these terms, you should not use
        our services.
      </p>
      <h2>1. Acceptance of Terms</h2>
      <p>
        These Terms of Service constitute a legally binding agreement between
        you and GreyHat Solutions. By using our website and services, you
        acknowledge that you have read, understood, and agree to be bound by
        these terms, along with our Privacy Policy.
      </p>
      <h2>2. Modifications to Terms</h2>
      <p>
        GreyHat Solutions reserves the right to modify or update these terms at
        any time without prior notice. It is your responsibility to review these
        terms periodically. Your continued use of our services following any
        changes indicates your acceptance of the revised terms.
      </p>
      <h2>3. Use of Our Services</h2>
      <p>
        You agree to use our services only for lawful purposes and in a manner
        consistent with all applicable laws and regulations. You are responsible
        for your conduct and any content you share while using our services.
        GreyHat Solutions reserves the right to terminate your access to our
        services if you violate these terms.
      </p>
      <h2>4. User Accounts</h2>
      <p>
        To access certain features of our services, you may need to create a
        user account. You agree to provide accurate and complete information
        when creating an account and to update this information as necessary.
        You are responsible for maintaining the confidentiality of your account
        credentials and for all activities that occur under your account.
      </p>
      <h2>5. Intellectual Property</h2>
      <p>
        All content on our website, including text, graphics, logos, images, and
        software, is the property of GreyHat Solutions or its licensors and is
        protected by intellectual property laws. You may not reproduce,
        distribute, or create derivative works based on our content without our
        express written permission.
      </p>
      <h2>6. Limitation of Liability</h2>
      <p>
        GreyHat Solutions is not liable for any direct, indirect, incidental, or
        consequential damages arising from your use of our services. This
        includes, but is not limited to, damages for loss of profits, data, or
        other intangible losses.
      </p>
      <h2>7. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless GreyHat Solutions, its
        affiliates, and its employees from any claims, damages, losses, or
        expenses arising out of your use of our services, your violation of
        these terms, or your infringement of any third-party rights.
      </p>
      <h2>8. Termination</h2>
      <p>
        GreyHat Solutions reserves the right to terminate your access to our
        services at any time for any reason, including, but not limited to, a
        violation of these terms. Upon termination, your right to use our
        services will immediately cease.
      </p>
      <h2>9. Governing Law</h2>
      <p>
        These terms are governed by and construed in accordance with the laws of
        the jurisdiction in which GreyHat Solutions operates. Any disputes
        arising from these terms shall be resolved in the courts of that
        jurisdiction.
      </p>
      <h2>10. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms of Service,
        please contact us at{" "}
        <a href="mailto:ncacord@protonmail.com">ncacord@protonmail.com</a>.
      </p>
      <div className="text-center">
        <button
          className="cta-button"
          onClick={handleBack}
          style={{
            marginTop: "40px",
            padding: "10px 20px",
            backgroundColor: "#00d1b2",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TermsOfService;
