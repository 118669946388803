import React, { useState, useEffect } from 'react';
import "./MobileHeader.css";

const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const collapseMenu = () => {
    setIsCollapsed(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="home-header">
      <h1>
        <a href="/staff-login" className="header-logo">
          GreyHat Solutions
        </a>
      </h1>
      <nav>
        {isMobile ? (
          <>
            <button className="header-button" onClick={toggleMenu}>
              {isCollapsed ? '☰' : '✖'}
            </button>
            {!isCollapsed && (
              <ul className="nav-menu">
                <li><a href="/" onClick={collapseMenu}>Home</a></li>
                <li><a href="/services" onClick={collapseMenu}>Services</a></li>
                <li><a href="/team" onClick={collapseMenu}>Meet the Team</a></li>
                <li><a href="/login" onClick={collapseMenu}>Client Login</a></li>
                <li><a href="/dashboard" onClick={collapseMenu}>Dashboard</a></li>
                <li><a href="/faqs" onClick={collapseMenu}>FAQs</a></li>
              </ul>
            )}
          </>
        ) : (
          <ul className="nav-menu">
            <li><a href="/">Home</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/team">Meet the Team</a></li>
            <li><a href="/login">Client Login</a></li>
            <li><a href="/dashboard">Dashboard</a></li>
            <li><a href="/faqs">FAQs</a></li>
          </ul>
        )}
      </nav>
    </header>
  );
};

export default Header;
