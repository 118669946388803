import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Header from '../components/Header';
import Footer from '../components/Footer';
import "./Home.css";
import "./Global.css";

const popularServices = [
  {
    title: "Firewall Management",
    description: "Manage and configure firewalls.",
    tags: ["Firewall", "Network Security", "Cybersecurity", "Intermediate"],
  },
  {
    title: "Custom PC Building",
    description: "Building custom PCs.",
    tags: ["Custom PC", "Hardware Customization", "Performance", "Intermediate"],
  },
  {
    title: "Machine Learning Services",
    description: "Services related to machine learning.",
    tags: ["Machine Learning", "AI Services", "Algorithms", "Intermediate"],
  }
];

const Home = () => {
  const spotlightMembers = [
    {
      name: "John Doe",
      role: "CEO",
      bio: "John has over 20 years of experience in IT and cybersecurity.",
      quote: "Innovation distinguishes between a leader and a follower.",
      backstory: "John's fascination with technology began in his childhood when he dismantled his first computer. His passion for cybersecurity grew over the years, leading him to found GreyHat Solutions.",
      image: "path-to-image.jpg",
    },
    {
      name: "Jane Smith",
      role: "CTO",
      bio: "Jane is an expert in cloud solutions and network management.",
      quote: "The cloud isn't just a place, it's a strategy.",
      backstory: "Jane's journey started in a small town where access to technology was limited. Her determination to bring advanced cloud solutions to underserved areas fueled her career.",
      image: "path-to-image.jpg",
    },
    {
      name: "Robert Brown",
      role: "Lead Developer",
      bio: "Robert is a coding wizard with a knack for solving complex problems.",
      quote: "Code is like humor. When you have to explain it, it’s bad.",
      backstory: "Robert fell in love with coding during high school and has since developed software for various industries. His expertise is now shaping the future of GreyHat Solutions.",
      image: "path-to-image.jpg",
    }
  ];

  return (
    <>
      <Header />
      <div className="content-wrapper">
        <section className="hero-section">
          <div className="hero-content">
            <h2>Welcome to GreyHat Solutions!</h2>
            <p>Your trusted partner in tech and IT services.</p>
            <a href="/services" className="cta-button">
              Get Started
            </a>
          </div>
        </section>

        <section className="spotlight-section">
          <h2>Team Member Spotlight</h2>
          <Carousel showThumbs={false} showStatus={false} infiniteLoop useKeyboardArrows>
            {spotlightMembers.map((member, index) => (
              <div key={index} className="team-member-carousel">
                <img src={member.image} alt={member.name} className="spotlight-photo" />
                <h3>{member.name}</h3>
                <p className="spotlight-role">{member.role}</p>
                <p className="spotlight-bio">{member.bio}</p>
                <blockquote className="spotlight-quote">"{member.quote}"</blockquote>
                <p className="spotlight-backstory">{member.backstory}</p>
              </div>
            ))}
          </Carousel>
        </section>

        <section className="popular-services-section">
          <h2>Popular Services</h2>
          <div className="popular-services">
            {popularServices.map((service, index) => (
              <div key={index} className="popular-service">
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
